import {
  Component,
  ElementRef,
  HostListener,
  HostBinding,
  Input,
  OnInit,
  EventEmitter,
  Renderer2,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  host: { 'class': 'tooltip' },
  styleUrls: ['./tooltip.component.css']
})

export class TooltipComponent {

  _show: boolean = false;


  @Input() data: any;


  events = new EventEmitter();

  @HostBinding('style.top') hostStyleTop: string;
  @HostBinding('style.left') hostStyleLeft: string;
  @HostBinding('style.z-index') hostStyleZIndex: number;
  @HostBinding('style.transition') hostStyleTransition: string;
  @HostBinding('style.max-width') hostStyleMaxWidth: string;
  @HostBinding('class.tooltip-show') hostClassShow: boolean;
  @HostBinding('class.tooltip-shadow') hostClassShadow: boolean;
  @HostBinding('class.tooltip-light') hostClassLight: boolean;

  @HostListener('transitionend', ['$event'])
  transitionEnd(event) {
    if (this.show) {
      this.events.emit('shown');
    }
  }

  @Input() set show(value: boolean) {
    if (value) {
      this.setPosition();
    }
    this._show = this.hostClassShow = value;
  }
  get show(): boolean {
    return this._show;
  }

  get placementForTips() {
    return this.data.options.placementForTips;
  }

  get element() {
    return this.data.element;
  }

  get elementPosition() {
    return this.data.elementPosition;
  }

  get options() {
    return this.data.options;
  }

  get value() {
    return this.data.value;
  }

  get tooltipOffset(): number {
    return Number(this.data.options.offset);
  }

  get tooltipRePositionX(): number {
    return Number(this.data.options.rePositionX);
  }

  get tooltipRePositionY(): number {
    return Number(this.data.options.rePositionY);
  }

  get elWordsWidth(): number {
    return this.data.options.wordsWidth.length;
  }

  get isThemeLight() {
    return this.options['theme'] === 'light';
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.setPlacementClass();
    this.setZIndex();
    this.setCustomClass();
    this.setAnimationDuration();
    this.setStyles();
  }

  ngAfterViewInit(): void { }

  setPosition(): void {
    // const elementHeight = this.element.offsetHeight;
    // const elementWidth = this.element.offsetWidth;
    const scrollY = window.pageYOffset;
    const scrollX = window.pageXOffset;
    const tooltip = this.elementRef.nativeElement;
    const right = this.data.elementPosition.right;
    const tooltipHeight = tooltip.clientHeight;
    const tooltipWidth = tooltip.clientWidth;
    const getClientWidth = () => {
      let clientWidth = 0;
      if (document.body.clientWidth && document.documentElement.clientWidth) {
        clientWidth = (document.body.clientWidth < document.documentElement.clientWidth) ? document.body.clientWidth : document.documentElement.clientWidth;
      } else {
        clientWidth = (document.body.clientWidth > document.documentElement.clientWidth) ? document.body.clientWidth : document.documentElement.clientWidth;
      }
      return clientWidth;
    };
    const getScrollLeft = () => {
      var scrollLeft = 0;
      if (document.documentElement && document.documentElement.scrollLeft) {
        scrollLeft = document.documentElement.scrollLeft;
      } else if (document.body) {
        scrollLeft = document.body.scrollLeft;
      }
      return scrollLeft;
    };
    const getScrollWidth = () => {
      return Math.max(document.body.scrollWidth, document.documentElement.scrollWidth);
    };
    const toRight = getScrollLeft() + getClientWidth() - this.data.mouseX;
    let isScrollX = document.body.scrollWidth > (window.innerWidth || document.documentElement.clientWidth);
    if (toRight <= 150 && isScrollX) {
      this.hostStyleTop = this.data.mouseY - tooltipHeight + this.tooltipRePositionY - 30 + 'px';
      this.hostStyleLeft = this.data.mouseX - parseInt(String(tooltipWidth / 2), 10) + this.tooltipRePositionX + 10 + 'px';
    } else if (toRight < 250 && toRight > 150 && isScrollX) {
      this.hostStyleTop = this.data.mouseY - tooltipHeight + this.tooltipRePositionY - 25 + 'px';
      this.hostStyleLeft = this.data.mouseX - parseInt(String(tooltipWidth / 2), 10) + this.tooltipRePositionX + 5 + 'px';
    } else {
      this.hostStyleTop = this.data.mouseY - tooltipHeight + this.tooltipRePositionY - 20 + 'px';
      this.hostStyleLeft = this.data.mouseX - parseInt(String(tooltipWidth / 2), 10) + this.tooltipRePositionX + 'px';
    }
    if (toRight < parseInt(String(tooltipWidth / 2), 10)) {
      this.hostStyleLeft = (parseInt(this.hostStyleLeft.substring(0, this.hostStyleLeft.length - 2), 10) - (parseInt(String(tooltipWidth / 2), 10) - toRight)) + 'px';
    }
    // console.log(this.data.mouseY - tooltipHeight - 20, 'top')
    // console.log(this.data.mouseX - parseInt(String(tooltipWidth / 2), 10), 'left')
    // console.log(this.data.mouseX-getScrollLeft());
    // const isSvg = this.element instanceof SVGElement;
    // const tooltip = this.elementRef.nativeElement;
    // const elementHeight = isSvg ? this.element.getBBox().height : this.element.offsetHeight;
    // const elementWidth = isSvg ? this.element.getBBox().width : this.element.offsetWidth;

    // const tooltipHeight = tooltip.clientHeight;
    // const tooltipWidth = tooltip.clientWidth;
    // const scrollY = window.pageYOffset;

    // if (this.placementForTips === 'top') {
    //   this.hostStyleTop = (this.elementPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset) + 'px';
    // }

    // if (this.placementForTips === 'bottom') {
    //   this.hostStyleTop = (this.elementPosition.top + scrollY) + elementHeight + this.tooltipOffset + 'px';
    // }

    // if (this.placementForTips === 'top' || this.placementForTips === 'bottom') {
    //   this.hostStyleLeft = (this.elementPosition.left + elementWidth / 2) - tooltipWidth / 2 + this.tooltipRePositionX + 'px';
    // }

    // if (this.placementForTips === 'left') {
    //   this.hostStyleLeft = this.elementPosition.left - tooltipWidth - this.tooltipOffset + 'px';
    // }

    // if (this.placementForTips === 'right') {
    //   this.hostStyleLeft = this.elementPosition.left + elementWidth + this.tooltipOffset + 'px';
    // }

    // if (this.placementForTips === 'left' || this.placementForTips === 'right') {
    //   this.hostStyleTop = (this.elementPosition.top + scrollY) + elementHeight / 2 - tooltip.clientHeight / 2 + 'px';
    // }
  }

  setPlacementClass(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-' + this.placementForTips);
  }

  setZIndex(): void {
    if (this.options['z-index'] !== 0) {
      this.hostStyleZIndex = this.options['z-index'];
    }
  }

  setCustomClass() {
    if (this.options['tooltip-class']) {
      this.renderer.addClass(this.elementRef.nativeElement, this.options['tooltip-class']);
    }
  }

  removeOriginalTitle() {

  }

  setAnimationDuration() {
    if (Number(this.options['animation-duration']) != this.options['animation-duration-default']) {
      this.hostStyleTransition = 'opacity ' + this.options['animation-duration'] + 'ms';
    }
  }

  setStyles() {
    this.hostClassShadow = this.options['shadow'];
    this.hostClassLight = this.isThemeLight;
    this.hostStyleMaxWidth = this.options['max-width'];
  }
}
