import { TooltipOptions } from './tooltip-options.interface';

export const defaultOptions: TooltipOptions = {
  'placementForTips': 'top',
  'content-type': 'string',
  'delay': 0,
  'show-delay': 0,
  'hide-delay': 0,
  'hide-delay-mobile': 0,
  'z-index': 0,
  'animation-duration': 0,
  'animation-duration-default': 0,
  'trigger': 'hover',
  'tooltip-class': '',
  'display': true,
  'display-mobile': true,
  'shadow': true,
  'theme': 'light',
  'offset': 0,
  'rePositionX': 0,
  'rePositionY': 0,
  'wordsWidth': '',
  'max-width': '',
};
